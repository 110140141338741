/* eslint-disable react/jsx-props-no-spreading */
import { CSVLink } from 'react-csv';
import React from 'react';
import {
  useAsyncDebounce, useGlobalFilter, useSortBy, useTable, useExpanded, usePagination,
} from 'react-table';
import PropTypes from 'prop-types';
// import moment from 'moment';
import Select from './Select';

const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      <input
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search in ${count} records...`}
      />
    </span>
  );
};

GlobalFilter.propTypes = {
  preGlobalFilteredRows: PropTypes.arrayOf(PropTypes.object).isRequired,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func.isRequired,
};

GlobalFilter.defaultProps = {
  globalFilter: undefined,
};

const Table = ({
  columns, data, withDownloadButton = false, withSearch = false, classes = '',
  centered = false, responsive = false,
  downloadFilename = 'export.csv', pageSize = 25,
  hiddenColumns = ['id'], excludeFromExport = [], defaultSortBy = null, defaultSortDesc = false,
  renderRowSubComponent = null,
}) => {
  const columnsMemo = React.useMemo(() => columns, [columns]);
  const dataMemo = React.useMemo(() => data, [data]);
  const headers = columnsMemo
    .filter((c) => c.accessor)
    .map((c) => ({ label: c.label || c.Header, key: c.accessor }));

  const sortTypes = {
    alphanumeric: (row1, row2, columnName) => {
      const rowOneColumn = row1.values[columnName];
      const rowTwoColumn = row2.values[columnName];

      // Order by raw values if presented
      if (`${columnName}_raw` in row1.original || `${columnName}_raw` in row2.original) {
        return row1.original[`${columnName}_raw`] > row2.original[`${columnName}_raw`] ? 1 : -1;
      }

      // If not a number
      if (Number.isNaN(Number(rowOneColumn))) {
        return rowOneColumn.toLowerCase() > rowTwoColumn.toLowerCase() ? 1 : -1;
      }

      // If number
      return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
    },
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    page,
    state: { pageIndex },
    previousPage,
    nextPage,
    setPageSize,
    canPreviousPage,
    canNextPage,
  } = useTable({
    columns: columnsMemo,
    data: dataMemo,
    sortTypes,
    autoResetGlobalFilter: false,
    initialState: {
      hiddenColumns,
      sortBy: [{ id: defaultSortBy, desc: defaultSortDesc }] },
  }, useGlobalFilter, useSortBy, useExpanded, usePagination);

  React.useEffect(() => {
    setPageSize(pageSize); // Dynamically set the page size using the function from react-table
  }, [pageSize, setPageSize]);

  const renderSortedIcon = (column) => {
    if (column.isSorted) {
      if (column.isSortedDesc) {
        return <i className="material-icons tiny hide-on-print">arrow_drop_down</i>;
      }
      return <i className="material-icons tiny hide-on-print">arrow_drop_up</i>;
    }
    return (
      <>
        <i className="material-icons tiny hide-on-print">arrow_drop_uparrow_drop_down</i>
      </>
    );
  };

  React.useEffect(() => {
    setPageSize(pageSize);
  }, [pageSize, setPageSize]);

  return (
    <div className={classes}>
      {
        withDownloadButton
        && (
          <div className="input-field col s12 m6">
            <CSVLink
              data={dataMemo.map((d) => {
                const row = {};

                headers.forEach((h) => {
                  // Skipt excluded columns
                  if (excludeFromExport.includes(h.key)) {
                    return;
                  }

                  // Key can be nested (e.g. 'doctor_profile.first_name'), so we need to recursively
                  // get the value (or set null it some level is missing)
                  const value = h.key.split('.').reduce((acc, key) => {
                    if (acc && key in acc) {
                      return acc[key];
                    }
                    return null;
                  }, d);

                  row[h.key] = value;
                });
                return row;
              })}
              headers={headers.filter((h) => {
                // Exclude columns from export
                if (excludeFromExport.includes(h.key)) {
                  return false;
                }
                return true;
              })}
              filename={downloadFilename}
              className="btn white black-text mb2"
            >
              <i className="material-icons left">file_download</i>
              Download
            </CSVLink>
          </div>
        )
      }

      {
        withSearch
        && (
          <div className="row">
            <div className="input-field col s12 m10">
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </div>
            <div className="input-field col s12 m2">
              <Select
                name="page_size"
                label="Page Size"
                values={{ 10: '10', 25: '25', 50: '50', 100: '100' }}
                defaultValue={String(pageSize)}
                onChange={(e) => setPageSize(Number(e.target.value))}
              />
            </div>
          </div>
        )
      }

      <table {...getTableProps()} className={`${centered ? 'centered' : ''} ${responsive ? 'responsive' : ''} `}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps([
                    column.getSortByToggleProps(),
                    {
                      className: column.className,
                    },
                  ])}
                >
                  {column.render('Header')}

                  {
                    !column.disableSortBy
                    && (
                      <span className="table-sort-icon">
                        {renderSortedIcon(column)}
                      </span>
                    )
                  }
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <React.Fragment key={row.id}>
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {
                    ...cell.getCellProps([
                      {
                        className: cell.column.className,
                        style: cell.column.style,
                      },
                      cell.getCellProps(),
                    ])}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
                {row.isExpanded ? renderRowSubComponent({ row }) : null}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>

      <div className="table-pagination">
        <div>
          Showing
          {' '}
          <em>
            {data.length && (pageIndex * pageSize + 1)}
            {' '}
            to
            {' '}
            {(pageIndex + 1) * pageSize > data.length ? data.length : (pageIndex + 1) * pageSize}
            {' '}
            of
            {' '}
            {data.length}
            {' '}
            entries
          </em>
        </div>

        <div className="flex-auto" />

        <div>
          <ul className="pagination">
            <li className={!canPreviousPage ? 'disabled' : ''}>
              <a onClick={(e) => e.preventDefault() || (canPreviousPage && previousPage())} href="#!">
                <i className="material-icons">chevron_left</i>
              </a>
            </li>

            <li className={!canNextPage ? 'disabled' : ''}>
              <a onClick={(e) => e.preventDefault() || (canNextPage && nextPage())} href="#!">
                <i className="material-icons">chevron_right</i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
      accessor: PropTypes.string,
      Cell: PropTypes.func,
    }),
  ).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  withDownloadButton: PropTypes.bool,
  withSearch: PropTypes.bool,
  centered: PropTypes.bool,
  responsive: PropTypes.bool,
  classes: PropTypes.string,
  downloadFilename: PropTypes.string,
  pageSize: PropTypes.number,
  // setPageSize: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
  excludeFromExport: PropTypes.arrayOf(PropTypes.string),
  defaultSortBy: PropTypes.string,
  defaultSortDesc: PropTypes.bool,
  renderRowSubComponent: PropTypes.func,
};

Table.defaultProps = {
  withDownloadButton: false,
  withSearch: false,
  classes: '',
  centered: false,
  responsive: false,
  downloadFilename: 'export.csv',
  pageSize: 25,
  // setPageSize: () => {},
  hiddenColumns: ['id'],
  excludeFromExport: [],
  defaultSortBy: null,
  defaultSortDesc: false,
  renderRowSubComponent: null,
};

export default Table;
