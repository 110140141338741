import moment from 'moment';
import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';

import NoData from '../../Components/NoData';
import {
  calculateAverageForKey, getAlertZonesPlots, getPlotData, reducer, round, sendRequest,
} from '../../utils';
import Loader from '../../Components/Loader';
import { useFetchAlertZones } from '../../hooks';
import { ENTRY_TYPE_TO_CODE_MAPPING } from './constants';

const PulsePlot = ({
  patient, startDate, endDate, isActive, forPrinting = false,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );
  const [, pulseAlertZones] = useFetchAlertZones(patient.patient.id, 2, 'Pulse');
  const [, spo2AlertZones] = useFetchAlertZones(patient.patient.id, 2, 'Spo2');

  const [type, setType] = React.useState('date');

  React.useEffect(() => {
    if (!isActive) {
      // Do not load anything while not active
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      entry_type: ENTRY_TYPE_TO_CODE_MAPPING.PulseOximeter,
      from_date: moment(startDate).utc().format(),
      to_date: moment(endDate).utc().format(),
    };

    sendRequest(`connections/patients/${patient.patient.id}/entries`, 'GET', opts)
      .then((response) => {
        if (response.status === 'error') {
          dispatch({
            type: 'FETCH_FAILURE',
            error: response.message,
            payload: [],
          });
        } else {
          const rawData = response.data;

          rawData
            .map((i) => ({
              ...i,
              created_at: moment(i.created_at),
            }))
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            });

          dispatch({
            type: 'FETCH_SUCCESS',
            payload: rawData,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });

    setType(moment(endDate).diff(moment(startDate), 'days') < 2 ? 'time' : 'date');
  }, [startDate, endDate, isActive]);

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [isActive]);

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (!state.data.length) {
    return (
      <NoData />
    );
  }

  let pulsePlot = getPlotData({
    rawData: state.data,
    xAttr: 'created_at',
    yAttr: 'pulse',
    label: 'Pulse',
    color: '#2196f3',
    aggregate: true,
    summarize: false,
  });

  // Add alert zones
  const pulseAlertZonePlots = getAlertZonesPlots(pulseAlertZones, pulsePlot[0].x, 'BPM', '#ef9a9a');

  pulsePlot = [...pulsePlot, ...pulseAlertZonePlots.flat()];

  const averagePulse = round(calculateAverageForKey(state.data, 'pulse'));

  const pulsePlotMinValue = Math.min(...pulsePlot[0]?.y);
  const pulsePlotMaxValue = Math.max(...pulsePlot[0]?.y);
  const pulsePlotMediumValue = round((pulsePlotMinValue + pulsePlotMaxValue) / 2);

  let spo2Plot = getPlotData({
    rawData: state.data,
    xAttr: 'created_at',
    yAttr: 'spo2',
    label: 'SpO<sub>2</sub>',
    color: '#2196f3',
    aggregate: true,
    summarize: false,
  });

  // Add alert zones
  const spo2AlertZonePlots = getAlertZonesPlots(spo2AlertZones, spo2Plot[0].x, 'spo2', '#ef9a9a');

  spo2Plot = [...spo2Plot, ...spo2AlertZonePlots.flat()];

  const averageSpO2 = round(calculateAverageForKey(state.data, 'spo2'));

  const spo2MinValue = Math.min(...spo2Plot[0]?.y);
  const spo2MaxValue = Math.max(...spo2Plot[0]?.y);
  const spo2MediumValue = round((spo2MinValue + spo2MaxValue) / 2);

  return (
    <>
      <div className="row mb4">
        <div className="col s12">
          {
          !Number.isNaN(averagePulse)
            ? (
              <Plot
                data={pulsePlot}
                style={{ width: '100%' }}
                layout={{
                  width: forPrinting ? '267mm' : null,
                  legend: { x: 0.01, y: -0.5, orientation: 'h' },
                  margin: {
                    l: 50, r: 20, b: 50, t: 50, pad: 2,
                  },
                  autosize: true,
                  yaxis: {
                    title: 'Pulse (BPM)',
                    nticks: 3,
                    tickvals: [pulsePlotMinValue, pulsePlotMediumValue, pulsePlotMaxValue],
                  },
                  xaxis: {
                    title: type === 'time' ? 'Time' : 'Date',
                    tickformat: type === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
                  },
                }}
                config={{
                  displayModeBar: false,
                }}
              />
            )
            : <NoData />
        }
        </div>

        <div className="col s12">
          {
          !Number.isNaN(averageSpO2)
            ? (
              <Plot
                data={spo2Plot}
                style={{ width: '100%' }}
                layout={{
                  width: forPrinting ? '267mm' : null,
                  legend: { x: 0.01, y: -0.5, orientation: 'h' },
                  margin: {
                    l: 50, r: 20, b: 50, t: 50, pad: 2,
                  },
                  autosize: true,
                  yaxis: {
                    title: 'SpO<sub>2</sub> (%)',
                    nticks: 3,
                    tickvals: [spo2MinValue, spo2MediumValue, spo2MaxValue],
                  },
                  xaxis: {
                    title: type === 'time' ? 'Time' : 'Date',
                    tickformat: type === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
                  },
                }}
                config={{
                  displayModeBar: false,
                }}
              />
            )
            : <NoData />
        }
        </div>
      </div>

      <div className="page-break-after"> </div>

      <div className="row mt4">
        <div className="col s6 m6">
          <div className="card blue-grey">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Pulse</span>
              <div className="center-align">
                {
                state.data.length
                  ? (
                    <>
                      <h4>{averagePulse}</h4>
                      bpm
                    </>
                  )
                  : <NoData />
              }
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="card teal">
            <div className="card-content white-text">
              <span className="card-title text-center">
                Average SpO
                <sub>2</sub>
              </span>
              <div className="center-align">
                {
                state.data.length && !Number.isNaN(averageSpO2)
                  ? (
                    <>
                      <h4>{averageSpO2}</h4>
                      bpm
                    </>
                  )
                  : <NoData />
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

PulsePlot.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  isActive: PropTypes.bool.isRequired,
  forPrinting: PropTypes.bool,
};

PulsePlot.defaultProps = {
  forPrinting: false,
};

export default PulsePlot;
