import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Plot from 'react-plotly.js';
import { getPlotData } from '../../../utils';

const getDataForTimersPlot = (timers) => {
  /*
  Helper function to get data for timers plot. This function fill empty days with 0 time spent.
  */
  const data = [];

  timers.forEach((timer, i) => {
    data.push({
      date_only: moment(timer.started_at).clone().toISOString(),
      // date_only: timer.started_at.format('MM/DD/YYYY'),
      spent_time: timer.time_spent,
    });

    // Generate missing data for chart
    const nextTimer = timers[i + 1];
    if (nextTimer) {
      const nextTimerStartTime = nextTimer.started_at;
      let diff = nextTimerStartTime.diff(timer.started_at.clone().startOf('day'), 'days');

      let nextDate = timer.started_at.clone();
      while (diff > 1) {
        nextDate = nextDate.add(1, 'days');

        data.push({
          date_only: nextDate.clone().toISOString(),
          // date_only: nextDate.format('MM/DD/YYYY'),
          spent_time: 0,
        });

        diff -= 1;
      }
    }
  });

  return data;
};

const TimeSpentGraph = ({ timersData, type, forPrinting = false }) => {
  const chartData = getDataForTimersPlot(timersData);

  const plotData = getPlotData({
    rawData: chartData,
    xAttr: 'date_only',
    yAttr: 'spent_time',
    label: 'Time Spent, minutes',
    color: '#2196f3',
    aggregate: true,
    summarize: true,
    withTrend: false,
  });

  return (
    <div style={{ minHeight: '450px' }}>
      <Plot
        data={plotData}
        style={{ width: '100%', marginBottom: '2rem' }}
        layout={{
          width: forPrinting ? '267mm' : null,
          margin: {
            l: 50, r: 50, b: 0, t: 20, pad: 0,
          },
          yaxis: {
            title: 'Time spent, minutes',
            automargin: true,
            rangemode: 'nonnegative',
            range: [0, Math.max(...plotData[0].y) + 10],
          },
          xaxis: {
            title: type === 'time' ? 'Time' : 'Date',
            automargin: true,
            // type: 'date',
            tickformat: type === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
          },
          autosize: true,
          legend: {
            x: 0, y: -0.15, orientation: 'h',
          },
        }}
        config={{
          displayModeBar: false,
          responsive: true,
        }}
      />
    </div>
  );
};

TimeSpentGraph.propTypes = {
  timersData: PropTypes.arrayOf(PropTypes.object).isRequired,
  type: PropTypes.string.isRequired,
  forPrinting: PropTypes.bool,
};

TimeSpentGraph.defaultProps = {
  forPrinting: false,
};

export default TimeSpentGraph;
