import React from 'react';

const Loader = () => (
  <div className="row mt4">
    <div className="col s4 offset-s4 center">
      <div className="preloader-wrapper active">
        <div className="spinner-layer spinner-only">
          <div className="circle-clipper left">
            <div className="circle" />
          </div>
          <div className="gap-patch">
            <div className="circle" />
          </div>
          <div className="circle-clipper right">
            <div className="circle" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Loader;
