/* eslint-disable react/prop-types */
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import M from '@materializecss/materialize';
import { STATUSES, SALES_REPS, TRIAL_DURATIONS } from './constants';
import Loader from '../Components/Loader';
import Table from '../Components/Table';
import { reducer, sendRequest } from '../utils';
import Select from '../Components/Select';

const DoctorStatus = ({ row: { original: cell } }) => {
  const onStatusChange = (e) => {
    const newStatus = e.target.value;

    // Ask for confirmation before freezing a doctor
    if (newStatus === 'frozen' && !window.confirm('Are you sure you want to freeze this doctor?')) {
      e.target.value = cell.status;
      M.FormSelect.init(e.target);
      return;
    }

    sendRequest(`admin/doctors/${cell.id}/sales_info/`, 'PUT', { ...cell.sales_info, status: newStatus })
      .then((resp) => {
        if (resp.status === 'success') {
          alert('Doctor status updated successfully');
        } else {
          alert(`Something went wrong. Error: ${resp.error}`);
        }
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.');
      });
  };

  return (
    <div>
      <Select
        name="status"
        label="Status"
        values={STATUSES}
        defaultValue={cell.sales_info?.status || ''}
        onChange={onStatusChange}
      />
    </div>
  );
};

DoctorStatus.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      sales_info: PropTypes.shape({
        status: PropTypes.string,
      }),
    }),
  }).isRequired,
};

const DoctorSalesRep = ({ row: { original: cell } }) => {
  const onSalesRepChange = (e) => {
    const newSalesRep = e.target.value;

    sendRequest(`admin/doctors/${cell.id}/sales_info/`, 'PUT', { ...cell.sales_info, sales_rep: newSalesRep })
      .then((resp) => {
        if (resp.status === 'success') {
          alert('Doctor sales rep updated successfully');
        } else {
          alert(`Something went wrong. Error: ${resp.error}`);
        }
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.');
      });
  };

  return (
    <div>
      <Select
        name="sales_rep"
        label="Sales Rep"
        values={SALES_REPS}
        defaultValue={cell.sales_info?.sales_rep}
        onChange={onSalesRepChange}
      />
    </div>
  );
};

DoctorSalesRep.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      sales_info: PropTypes.shape({
        sales_rep: PropTypes.string,
      }),
    }),
  }).isRequired,
};

const DoctorTrialDuration = ({ row: { original: cell } }) => {
  const onTrialDurationChange = (e) => {
    const newTrialDuration = e.target.value;

    sendRequest(`admin/doctors/${cell.id}/sales_info/`, 'PUT', { ...cell.sales_info, trial_duration_in_days: Number(newTrialDuration) })
      .then((resp) => {
        if (resp.status === 'success') {
          alert('Doctor trial duration updated successfully');
        } else {
          alert(`Something went wrong. Error: ${resp.error}`);
        }
      })
      .catch(() => {
        alert('Something went wrong. Please try again later.');
      });
  };

  return (
    <div>
      <Select
        name="trial_duration"
        label="Trial Duration"
        values={TRIAL_DURATIONS}
        defaultValue={cell.sales_info?.trial_duration_in_days || ''}
        onChange={onTrialDurationChange}
      />
    </div>
  );
};

DoctorTrialDuration.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      sales_info: PropTypes.shape({
        trial_duration_in_days: PropTypes.number,
      }),
    }),
  }).isRequired,
};

const DateCell = ({ value }) => (
  <div>
    {
    value
      ? moment.utc(value, 'YYYY-MM-DD h:mm:ss').local().format('L')
      : 'No date'
    }
  </div>
);

DateCell.propTypes = {
  value: PropTypes.string,
};

DateCell.defaultProps = {
  value: '',
};

const DeviceCell = ({ value }) => {
  if (!value || value.length === 0) {
    return (
      <div>
        No devices
      </div>
    );
  }

  // Format of device object: {device_model: 'DMD1085', total: 4}
  // Need to render as: DMD1085 x 4 and total line in the end
  const deviceModels = value.map((device) => `${device.device_model} x ${device.total}`);
  const total = value.reduce((acc, device) => acc + device.total, 0);

  return (
    <div>
      {
        deviceModels.map((model) => (
          <div key={model}>
            {model}
          </div>
        ))
      }
      <hr />
      {
        total > 0 && (
          <div>
            <strong>
              Total:
              {' '}
              {total}
            </strong>
          </div>
        )
      }
    </div>
  );
};

const TimeUsing = ({ value }) => (
  <div>{moment.utc(value).fromNow(true)}</div>
);

TimeUsing.propTypes = {
  value: PropTypes.string.isRequired,
};

const DoctorsTable = ({ doctors, actions }) => {
  const DoctorActions = ({ row: { original: cell } }) => (
    <div>
      {actions.map((action) => (
        <a
          key={action.label}
          className={`btn mr2 mb2 ${action.color || 'black'}`}
          href="#/"
          onClick={() => action.callback(cell)}
        >
          {action.label}
        </a>
      ))}
    </div>
  );

  DoctorActions.propTypes = {
    row: PropTypes.shape({
      original: PropTypes.shape({
        id: PropTypes.string,
      }),
    }).isRequired,
  };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Doctor Name',
      accessor: 'plain_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Sales Rep',
      Cell: DoctorSalesRep,
      accessor: 'sales_info.sales_rep',
      style: { minWidth: '150px' },
    },
    {
      Header: 'Status',
      Cell: DoctorStatus,
      accessor: 'status',
      style: { minWidth: '150px' },
    },
    {
      id: 'time_using',
      Header: 'Time Using',
      accessor: 'doctor_profile.created_at',
      Cell: TimeUsing,
    },
    {
      Header: 'Trial Duration',
      Cell: DoctorTrialDuration,
      accessor: 'sales_info.trial_duration_in_days',
    },
    {
      Header: 'Date First Patient Added',
      accessor: 'date_first_patient_added',
      Cell: DateCell,
    },
    {
      Header: 'Date Last Patient Added',
      accessor: 'date_last_patient_added',
      Cell: DateCell,
    },
    {
      Header: 'Devices',
      accessor: 'number_of_devices_by_sku',
      Cell: DeviceCell,
    },
    {
      id: 'created_at',
      Header: 'Created At',
      accessor: 'doctor_profile.created_at',
      Cell: DateCell,
    },
    {
      Header: 'Last Sign In At',
      accessor: 'last_login',
      Cell: DateCell,
    },
    {
      Header: 'Number Of Patients',
      accessor: 'number_of_patients',
    },
    // {
    //   Header: 'Actions',
    //   Cell: DoctorActions,
    // },
    {
      id: 'expander',
      Header: () => null,
      Cell: ({ row }) => (
        <span className="btn btn-primary black" {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? 'Hide Colleagues' : 'Show Colleagues'}
        </span>
      ),
    },
  ];

  const renderCellContent = (colleague, column) => {
    if (column.accessor === 'sales_info.sales_rep') {
      return ''; // Render empty for sales_rep column in child rows
    }

    if (column.accessor === 'status') {
      return ''; // Render empty for status column in child rows
    }

    if (column.accessor === 'sales_info.trial_duration_in_days') {
      return ''; // Render empty for trial_duration column in child rows
    }

    if (column.Cell) {
      return column.Cell({
        value: column.accessor.split('.').reduce((acc, part) => acc && acc[part], colleague),
      });
    }

    return colleague[column.accessor];
  };

  const renderRowSubComponent = React.useCallback(
    ({ row }) => (
      <>
        {row.original.colleagues.map((colleague) => (
          <tr key={colleague.id}>
            {columns.slice(0, -1).filter((c) => !['id', 'email', 'plain_name'].includes(c.accessor)).map((column) => (
              <td key={column.id || column.accessor}>
                {renderCellContent(colleague, column)}
              </td>
            ))}
          </tr>
        ))}
      </>
    ),
    [columns],
  );

  return (
    <Table
      classes="doctors-table"
      columns={columns}
      data={doctors}
      withSearch
      withDownloadButton
      centered
      responsive
      hiddenColumns={['id', 'email', 'plain_name']}
      excludeFromExport={['name']}
      renderRowSubComponent={renderRowSubComponent}
    />
  );
};

DoctorsTable.propTypes = {
  doctors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.object.isRequired,
    doctor_profile: PropTypes.shape({
      created_at: PropTypes.string.isRequired,
    }),
  })).isRequired,
  actions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    callback: PropTypes.func.isRequired,
    color: PropTypes.string,
  })).isRequired,
};

const DoctorsDashboard = () => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  React.useEffect(() => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest('admin/doctors', 'GET')
      .then((response) => {
        const doctors = response.data.map((doctor) => ({
          ...doctor,
          plain_name: `${doctor.first_name} ${doctor.last_name}`,
          name: (
            <>
              { `${doctor.first_name} ${doctor.last_name}` }
              <br />
              <small>{doctor.email}</small>
            </>
          ),
          name_raw: `${doctor.first_name} ${doctor.last_name}`,
          time_using: moment.duration(moment.utc() - moment.utc(doctor.doctor_profile.created_at)),
          colleagues: doctor.colleagues.map((colleague) => ({
            ...colleague,
            plain_name: `${colleague.first_name} ${colleague.last_name}`,
            name: (
              <>
                { `${colleague.first_name} ${colleague.last_name}` }
                <br />
                <small>{colleague.email}</small>
              </>
            ),
            name_raw: `${colleague.first_name} ${colleague.last_name}`,
            time_using: moment.duration(moment.utc() - moment.utc(colleague.doctor_profile.created_at)),
          })),
        }));

        dispatch({
          type: 'FETCH_SUCCESS',
          payload: doctors,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error });
      });
  }, []);

  return (
    <div className="container-fluid mt4 mb4">
      <a href="/admin/" className="btn btn-small white black-text">
        <i className="material-icons left">arrow_back</i>
        Go Back to Admin Dashboard
      </a>

      <h4>Doctors</h4>

      <div>
        {state.isError && <p>Something went wrong ...</p>}

        {state.message && <p className="form-messages">{state.message}</p>}

        {state.isLoading ? (
          <Loader />
        ) : (
          <DoctorsTable
            doctors={state.data}
            actions={[]}
          />
        )}
      </div>
    </div>
  );
};

export default DoctorsDashboard;
