import React from 'react';
import PropTypes from 'prop-types';
import RangeButtonsGroup from '../DetailsButtons';
import SummaryTable from './SummaryTable';
import SpentTimeSummary from './TimeSpent/TimeSpentSummary';

const Summary = ({ patientId, filters, onFiltersChange }) => {
  const handleIntervalChanges = ({ interval, period, startDate, endDate }) => {
    onFiltersChange({ interval, period, startDate, endDate });
  };

  return (
    <>
      <RangeButtonsGroup
        defaultInterval={filters.interval}
        defaultPeriod={filters.period}
        initialStartDate={filters.startDate}
        initialEndDate={filters.endDate}
        callback={handleIntervalChanges}
      />

      <SummaryTable patientId={patientId} startDate={filters.startDate} endDate={filters.endDate} />

      <SpentTimeSummary patientId={patientId} startDate={filters.startDate} endDate={filters.endDate} />
    </>
  );
};

Summary.propTypes = {
  patientId: PropTypes.string.isRequired,
  filters: PropTypes.shape({
    interval: PropTypes.string.isRequired,
    period: PropTypes.number,
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }).isRequired,
  onFiltersChange: PropTypes.func.isRequired,
};

export default Summary;
