import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Table from '../../../Components/Table';

function TimerHistoryModal({ timerId, history, currentStartDate, currentEndDate }) {
  const columns = [
    {
      Header: 'Change ID',
      accessor: 'id',
      className: 'center relative',
    },
    {
      Header: 'Change Date',
      accessor: 'timestamp',
      className: 'center relative',
    },
    {
      Header: 'Changes',
      accessor: 'changes',
      className: 'center relative',
    },
  ];

  const data = history.map((item) => ({
    id: item.id,
    // Changes are in format like "{\"ended_at\": [\"2024-11-12 12:46:00\", \"2024-11-12 13:46:00\"]}"
    changes: Object.entries(JSON.parse(item.changes)).map(([key, value]) => {
      if (key === 'started_at') {
        return `Started date changed to ${moment(value[1]).format('MM/DD/YYYY HH:mm:ss')}`;
      } if (key === 'ended_at') {
        return `Ended date changed to ${moment(value[1]).format('MM/DD/YYYY HH:mm:ss')}`;
      }
      return `${key.replace('_', ' ')} changed to ${value[1]}`;
    }).join('; '),
    timestamp: moment(item.timestamp).format('MM/DD/YYYY HH:mm:ss'),
  }));

  return (
    <>
      <div className="modal-content">
        <h5>Timer History</h5>
        <p>
          <strong>Timer ID:</strong>
          {' '}
          {timerId}
          <br />
          <strong>Current Start Date:</strong>
          {' '}
          {moment(currentStartDate).format('MM/DD/YYYY HH:mm:ss')}
          <br />
          <strong>Current End Date:</strong>
          {' '}
          {moment(currentEndDate).format('MM/DD/YYYY HH:mm:ss')}
        </p>

        <Table columns={columns} data={data} classes="pl2 pr2" />
      </div>
      <div className="modal-footer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem' }}>
        <button type="button" className="modal-close btn white black-text">Close</button>
      </div>
    </>
  );
}

TimerHistoryModal.propTypes = {
  timerId: PropTypes.number.isRequired,
  history: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentStartDate: PropTypes.string.isRequired,
  currentEndDate: PropTypes.string.isRequired,
};

export default TimerHistoryModal;
