import moment from 'moment';
import Plot from 'react-plotly.js';
import React from 'react';
import PropTypes from 'prop-types';

import NoData from '../../Components/NoData';
import {
  calculateAverageForKey, getPlotData, getAlertZonesPlots, reducer, round, sendRequest,
} from '../../utils';
import Loader from '../../Components/Loader';
import { useFetchAlertZones } from '../../hooks';
import { ENTRY_TYPE_TO_CODE_MAPPING } from './constants';

const BloodPressurePlot = ({
  patient, startDate, endDate, isActive, forPrinting = false,
}) => {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  React.useEffect(() => {
    if (!isActive) {
      // Do not load anything while not active
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      entry_type: ENTRY_TYPE_TO_CODE_MAPPING.BloodPressure,
      from_date: moment(startDate).utc().format(),
      to_date: moment(endDate).utc().format(),
    };

    sendRequest(`connections/patients/${patient.patient.id}/entries`, 'GET', opts)
      .then((response) => {
        if (response.status === 'error') {
          dispatch({
            type: 'FETCH_FAILURE',
            error: response.message,
            payload: [],
          });
        } else {
          const rawData = response.data;

          rawData
            .map((i) => ({
              ...i,
              created_at: moment(i.created_at),
            }))
            .sort((a, b) => {
              if (a.created_at > b.created_at) {
                return 1;
              }
              if (a.created_at < b.created_at) {
                return -1;
              }
              return 0;
            });

          dispatch({
            type: 'FETCH_SUCCESS',
            payload: rawData,
          });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  }, [startDate, endDate, isActive]);

  React.useEffect(() => {
    window.dispatchEvent(new Event('resize'));
  }, [isActive]);

  if (state.isLoading) {
    return (
      <Loader />
    );
  }

  if (!state.data.length) {
    return (
      <NoData />
    );
  }

  return (
    <>
      <div className="row mb4">
        <BloodPressureGraph
          patientId={patient.patient.id}
          data={state.data}
          // Send 'time' as type if time interval is less than 2 days, otherwise send 'date'
          // to display date and time in the x-axis
          type={moment(endDate).diff(moment(startDate), 'days') < 2 ? 'time' : 'date'}
          forPrinting={forPrinting}
        />
      </div>

      <div className="page-break-after"> </div>

      <div className="row mt4">
        <BloodPressureAverages entries={state.data} />
      </div>
    </>
  );
};

BloodPressurePlot.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  isActive: PropTypes.bool.isRequired,
  forPrinting: PropTypes.bool,
};

BloodPressurePlot.defaultProps = {
  forPrinting: false,
};

const BloodPressureGraph = ({ patientId, data, type, forPrinting = false }) => {
  const plotEl = React.useRef(null);

  const [, systolicAlertZones] = useFetchAlertZones(patientId, 1, 'Systolic');
  const [, diastolicAlertZones] = useFetchAlertZones(patientId, 1, 'Diastolic');
  const [, pulseAlertZones] = useFetchAlertZones(patientId, 2, 'Pulse');

  const systolicPlot = getPlotData({
    rawData: data,
    xAttr: 'created_at',
    yAttr: 'systolic',
    label: 'Systolic',
    color: '#4caf50',
    legendGroup: 'systolic_plot',
    aggregate: true,
    summarize: false,
  });
  const diastolicPlot = getPlotData({
    rawData: data,
    xAttr: 'created_at',
    yAttr: 'diastolic',
    label: 'Diastolic',
    color: '#4caf50',
    legendGroup: 'diastolic_plot',
    aggregate: true,
    summarize: false,
  });
  const pulsePlot = getPlotData({
    rawData: data,
    xAttr: 'created_at',
    yAttr: 'pulse',
    label: 'Pulse',
    color: '#4caf50',
    legendGroup: 'pulse_plot',
    aggregate: true,
    summarize: false,
  });

  systolicPlot.yaxis = 'y';
  diastolicPlot.yaxis = 'y2';
  pulsePlot.yaxis = 'y3';

  // Add alert zones
  const systolicAlertZonePlots = getAlertZonesPlots(
    systolicAlertZones,
    systolicPlot[0].x,
    'mmHg',
    '#ef9a9a',
  );

  systolicAlertZonePlots.forEach((p, i) => { systolicAlertZonePlots[i].yaxis = 'y'; });

  const diastolicAlertZonePlots = getAlertZonesPlots(
    diastolicAlertZones,
    diastolicPlot[0].x,
    'mmHg',
    '#ef9a9a',
    false,
  );

  diastolicAlertZonePlots.forEach((p, i) => { diastolicAlertZonePlots[i].yaxis = 'y2'; });

  const pulseAlertZonePlots = getAlertZonesPlots(
    pulseAlertZones,
    pulsePlot[0].x,
    'BPM',
    '#ef9a9a',
    false,
  );

  pulsePlot.forEach((p, i) => { pulsePlot[i].yaxis = 'y3'; });
  pulseAlertZonePlots.flat().forEach((p, i) => { pulseAlertZonePlots.flat()[i].yaxis = 'y3'; });

  // Update Y-axes to render as subplots
  systolicPlot.forEach((p, i) => { systolicPlot[i].yaxis = 'y'; });
  systolicAlertZonePlots.flat().forEach((p, i) => { systolicAlertZonePlots.flat()[i].yaxis = 'y'; });

  diastolicPlot.forEach((p, i) => { diastolicPlot[i].yaxis = 'y2'; });
  diastolicAlertZonePlots.flat().forEach((p, i) => { diastolicAlertZonePlots.flat()[i].yaxis = 'y2'; });

  pulsePlot.forEach((p, i) => { pulsePlot[i].yaxis = 'y3'; });
  pulseAlertZonePlots.flat().forEach((p, i) => { pulseAlertZonePlots.flat()[i].yaxis = 'y3'; });

  const getBBox = (el) => {
    const tempDiv = document.createElement('div');
    tempDiv.setAttribute('style', 'position:absolute; visibility:hidden; width:0; height:0');
    document.body.appendChild(tempDiv);
    const tempSvg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    tempDiv.appendChild(tempSvg);
    const tempEl = el.cloneNode(true);
    tempSvg.appendChild(tempEl);
    const bbox = tempEl.getBBox();
    document.body.removeChild(tempDiv);
    return bbox;
  };

  const drawDelimiters = () => {
    const systolicPressureG = plotEl.current.el.querySelector('g.xy');
    const diastolicPressureG = plotEl.current.el.querySelector('g.xy2');

    if (systolicPressureG.lastChild.tagName !== 'line') {
      // Calculate width of line
      // Use a function because SVG can be hidden
      const bbox = getBBox(systolicPressureG);
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', bbox.x);
      line.setAttribute('y1', bbox.y + bbox.height);
      line.setAttribute('x2', bbox.width);
      line.setAttribute('y2', bbox.y + bbox.height);
      line.setAttribute('style', 'stroke-width: 1px; stroke-dasharray: none; stroke: black;');

      // Add new child in the end of <g>
      systolicPressureG.appendChild(line);
    }

    if (diastolicPressureG.lastChild.tagName !== 'line') {
      // Calculate width of line
      const bbox = getBBox(diastolicPressureG);
      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', bbox.x);
      line.setAttribute('y1', bbox.y + bbox.height);
      line.setAttribute('x2', bbox.width);
      line.setAttribute('y2', bbox.y + bbox.height);
      line.setAttribute('style', 'stroke-width: 1px; stroke-dasharray: none; stroke: black;');

      // Add new child in the end of <g>
      diastolicPressureG.appendChild(line);
    }
  };

  // This function used to add some spacing between subplots
  const getDomain = (i) => {
    const N = 3;
    const spacing = 0.15;
    const spacingPerFace = spacing / ((N - 1) * 2);
    const expectedDomain = (1 - spacing) / N;

    const previousDomainLocked = expectedDomain * i;
    const previousSpaceLocked = spacingPerFace * (i * 2 - 1);
    const endValue = previousDomainLocked
    + previousSpaceLocked
    + expectedDomain
    + spacingPerFace;

    return [
      i === 0 ? 0 : previousDomainLocked + previousSpaceLocked + spacingPerFace,
      i === N - 1 ? 1 : endValue,
    ];
  };

  return (
    <Plot
      ref={plotEl}
      data={[
        ...systolicPlot, ...diastolicPlot, ...pulsePlot,
        ...systolicAlertZonePlots.flat(),
        ...diastolicAlertZonePlots.flat(),
        ...pulseAlertZonePlots.flat(),
      ]}
      useResizeHandler
      style={{ width: '100%' }}
      layout={{
        width: forPrinting ? '267mm' : null,
        height: 900,
        legend: {
          x: 0, y: -0.15, orientation: 'h',
        },
        margin: {
          l: 50, r: 50, b: 0, t: 0, pad: 0,
        },
        hovermode: 'x unified',
        hoverlabel: {
          width: '200px',
        },
        autosize: true,
        yaxis: {
          title: 'Systolic (mmHg)',
          tickmode: 'array',
          tickvals: [80, 200],
          range: [75, 205],
          domain: getDomain(2),
        },
        yaxis2: {
          title: 'Diastolic (mmHg)',
          tickmode: 'array',
          tickvals: [60, 120],
          range: [55, 125],
          domain: getDomain(1),
        },
        yaxis3: {
          title: 'Pulse (BPM)',
          tickmode: 'array',
          tickvals: [40, 180],
          range: [35, 185],
          domain: getDomain(0),
        },
        xaxis: {
          title: type === 'time' ? 'Time' : 'Date',
          // Show only time in format 5:00 PM if the startDate / endDate range is less than 2 days,
          // otherwise show date and time in format 2020-01-01 5:00 PM
          tickformat: type === 'time' ? '%-I:%M %p' : '%m/%d/%Y',
        },
        grid: {
          rows: 3,
          columns: 1,
          // pattern: 'independent',
          // roworder: 'bottom to top',
        },
      }}
      config={{
        displayModeBar: false,
        responsive: true,
      }}
      onAfterPlot={drawDelimiters}
    />
  );
};

BloodPressureGraph.propTypes = {
  patientId: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      systolic: PropTypes.number.isRequired,
      diastolic: PropTypes.number.isRequired,
    }),
  ).isRequired,
  type: PropTypes.string.isRequired,
  forPrinting: PropTypes.bool,
};

BloodPressureGraph.defaultProps = {
  forPrinting: false,
};

export const BloodPressureAverages = ({ entries }) => {
  // First two cards
  const averageSystolic = round(calculateAverageForKey(entries, 'systolic'));
  const averageDiastolic = round(calculateAverageForKey(entries, 'diastolic'));
  const averageBPM = round(calculateAverageForKey(entries, 'pulse'));

  // The second row of cards (AM/PM)
  const entriesAM = entries.filter((e) => moment(e.created_at).format('A') === 'AM');
  const entriesPM = entries.filter((e) => moment(e.created_at).format('A') === 'PM');

  const systolicAM = round(calculateAverageForKey(entriesAM, 'systolic'));
  const diastolicAM = round(calculateAverageForKey(entriesAM, 'diastolic'));

  const systolicPM = round(calculateAverageForKey(entriesPM, 'systolic'));
  const diastolicPM = round(calculateAverageForKey(entriesPM, 'diastolic'));

  return (
    <>
      <div className="row">
        <div className="col s6 m6">
          <div className="card teal">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Blood Pressure</span>
              <div className="center-align">
                {
                averageSystolic && averageDiastolic
                  ? (
                    <>
                      <h4>{`${averageSystolic} / ${averageDiastolic}`}</h4>
                      mmHg
                    </>
                  )
                  : <h4>Not Enough Data</h4>
              }
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="card teal">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Pulse</span>
              <div className="center-align">
                {
                averageBPM
                  ? (
                    <>
                      <h4>{averageBPM}</h4>
                      bpm
                    </>
                  )
                  : <h4>Not Enough Data</h4>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col s6 m6">
          <div className="card blue-grey">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Blood Pressure: AM</span>
              <div className="center-align">
                <h4>
                  {
                  systolicAM && diastolicAM
                    ? `${systolicAM} / ${diastolicAM}`
                    : 'Not Enough Data'
                }
                </h4>
                mmHg
              </div>
            </div>
          </div>
        </div>
        <div className="col s6 m6">
          <div className="card blue-grey">
            <div className="card-content white-text">
              <span className="card-title text-center">Average Blood Pressure: PM</span>
              <div className="center-align">
                <h4>
                  {
                  systolicPM && diastolicPM
                    ? `${systolicPM} / ${diastolicPM}`
                    : 'Not Enough Data'
                }
                </h4>
                mmHg
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

BloodPressureAverages.propTypes = {
  entries: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      systolic: PropTypes.number.isRequired,
      diastolic: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default BloodPressurePlot;
