import React from 'react';
import M from '@materializecss/materialize';

import { reducer, sendRequest } from '../utils';
import Select from '../Components/Select';

function BillingReportModal() {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );

  const [patients, setPatients] = React.useState([]);

  const [patientId, setPatientId] = React.useState('');
  const [billingDateStart, setBillingDateStart] = React.useState('');
  const [cptCode, setCptCode] = React.useState('');

  const handleFetchPatients = () => {
    sendRequest('connections/patients', 'GET')
      .then((response) => {
        setPatients([
          ['', 'Choose Patient'],
          ...response.data.map((p) => [p.patient.id, `${p.patient.first_name || ''} ${p.patient.last_name || ''}`]),
        ]);
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  React.useEffect(() => {
    handleFetchPatients();
  }, []);

  React.useEffect(() => {
    const elems = document.querySelectorAll('input[name="billing_date_start"]');
    M.Datepicker.init(elems, {
      autoClose: true,
      format: 'mm/dd/yyyy',
      onSelect: (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const year = date.getFullYear();
        setBillingDateStart(`${year}-${month}-${day}`);
      },
    });

    M.updateTextFields();
  }, []);

  const onPatientChange = (e) => {
    setPatientId(e.target.value);
  };

  const onCPTCodeChange = (e) => {
    setCptCode(e.target.value);
  };

  const onGenerateReportButtonClick = (e) => {
    e.preventDefault();

    if (!patientId) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Patient is required' });
      return;
    }

    if (!cptCode) {
      dispatch({ type: 'FETCH_FAILURE', error: 'CPT Code is required' });
      return;
    }

    if (cptCode === '99454' && !billingDateStart) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Billing Start Date is required' });
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      code: cptCode,
      billing_date_start: billingDateStart,
    };

    sendRequest(`connections/patients/${patientId}/report/`, 'GET', opts)
      .then((response) => {
        if (response.blob) {
          const url = window.URL.createObjectURL(new Blob([response.blob]));

          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', response.filename || 'Billing Report.pdf');

          document.body.appendChild(link);
          link.click();

          // Clean up by removing the link
          link.parentNode.removeChild(link);

          // Optionally, if you're dispatching based on success
          dispatch({ type: 'FETCH_SUCCESS', message: 'Report generated successfully' });
        } else if (response.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', data: response.data });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  return (
    <>
      <div className="modal-content">
        <div className="container-fluid">
          <h5 className="pb2 text-center">Billing Report</h5>
          <form>
            <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>
            <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>

            <div className="row">
              <div className="input-field col s12">
                <Select
                  name="patient"
                  label="Patient"
                  values={patients}
                  defaultValue=""
                  onChange={onPatientChange}
                />
              </div>

              <div className="input-field col s12">
                <Select
                  name="cpt_code"
                  label="CPT Code"
                  values={[
                    ['', 'Choose CPT Code'], // Add an empty option
                    ['99454', '99454'],
                    ['99457', '99457'],
                    ['99458', '99458'],
                  ]}
                  defaultValue=""
                  onChange={onCPTCodeChange}
                />
              </div>

              <div className="input-field col s12" style={{ display: cptCode === '99454' ? 'block' : 'none' }}>
                <input
                  id="billing_date_start"
                  name="billing_date_start"
                  type="text"
                  className="datepicker"
                  dataformat="mm/dd/yyyy"
                  // onChange={onBillingDateStartChange}
                />
                <label htmlFor="from_date">Billing Start Date</label>
              </div>

            </div>
          </form>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn black mr2" onClick={onGenerateReportButtonClick}>Generate Report</button>
        <button type="button" className="modal-close btn white black-text">Close</button>
      </div>
    </>
  );
}

export default BillingReportModal;
