import React from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import GeneralSettingsForm from './GeneralSettingsForm';
import BillingSettings from './BillingSettings';

const MySettings = () => (
  <div className="container mt2 mb2">

    <div className="row">
      <div className="col s12">
        <h4 className="mb4">My Settings</h4>
        <ul className="tabs">
          <li className="tab col s3"><a className="active" href="#general">General</a></li>
          <li className="tab col s3"><a href="#password">Security</a></li>
          <li className="tab col s3"><a href="#billing">Billing</a></li>
        </ul>
      </div>
      <div id="general" className="col s12">
        <GeneralSettingsForm />
      </div>

      <div id="password" className="col s12">
        <ChangePasswordForm />
      </div>

      <div id="billing" className="col s12">
        <BillingSettings />
      </div>
    </div>
  </div>
);

export default MySettings;
