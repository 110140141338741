import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { reducer, sendRequest } from '../../../utils';
import Loader from '../../../Components/Loader';
import { TimerStatusContext } from '../../../Context';
import TimersTable from './TimersTable';
import TimeSpentGraph from './TimeSpentGraph';

const getDataForTimersPlot = (timers) => {
  /*
  Helper function to get data for timers plot. This function fill empty days with 0 time spent.
  */
  const data = [];

  timers.forEach((timer, i) => {
    data.push({
      date_only: moment(timer.started_at).clone().toISOString(),
      // date_only: timer.started_at.format('MM/DD/YYYY'),
      spent_time: timer.time_spent,
    });

    // Generate missing data for chart
    const nextTimer = timers[i + 1];
    if (nextTimer) {
      const nextTimerStartTime = nextTimer.started_at;
      let diff = nextTimerStartTime.diff(timer.started_at.clone().startOf('day'), 'days');

      let nextDate = timer.started_at.clone();
      while (diff > 1) {
        nextDate = nextDate.add(1, 'days');

        data.push({
          date_only: nextDate.clone().toISOString(),
          // date_only: nextDate.format('MM/DD/YYYY'),
          spent_time: 0,
        });

        diff -= 1;
      }
    }
  });

  return data;
};

const getPrettyTime = (totalMinutes) => {
  const diff = moment.duration(totalMinutes * 60 * 1000);

  const days = diff.days();
  const hours = (`0${days * 24 + diff.hours()}`).slice(-2);
  const minutes = (`0${diff.minutes()}`).slice(-2);
  const seconds = (`0${diff.seconds()}`).slice(-2);

  return `${hours}:${minutes}:${seconds}`;
};

const SpentTimeSummary = ({ patientId, startDate, endDate, forPrinting = false }) => {
  const [timeSpent, setTimeSpent] = React.useState(0);
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: false, isError: false },
  );
  const [timers, setTimers] = React.useState([]);
  const [type, setType] = React.useState('date');

  // Context to track timer status
  const timerStatus = React.useContext(TimerStatusContext);

  const fetchTimers = () => {
    dispatch({ type: 'FETCH_INIT' });

    sendRequest(`connections/patients/${patientId}/time_trackers`, 'GET')
      .then((response) => {
        if (response.status === 'success') {
          const timers = response.data.time_trackers
            .filter((t) => t.ended_at)
            .map((t) => ({
              ...t,
              started_at: moment.utc(t.started_at).local(),
              ended_at: moment.utc(t.ended_at).local(),
              time_spent: (moment.utc(t.ended_at) - moment.utc(t.started_at)) / 1000 / 60,
            }))
            .filter((t) => t.started_at >= startDate && t.ended_at <= endDate);

          // Add missed days to data
          const chartData = getDataForTimersPlot(timers);
          const totalTimeSpent = timers.reduce((acc, t) => acc + t.time_spent, 0);

          setTimeSpent(totalTimeSpent);
          setTimers(timers);
          dispatch({ type: 'FETCH_SUCCESS', payload: chartData });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
        }
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
        dispatch({ type: 'FETCH_FAILURE', error: 'Something went wrong...' });
      });
  };

  React.useEffect(() => {
    fetchTimers();

    setType(moment(endDate).diff(moment(startDate), 'days') < 2 ? 'time' : 'date');
  }, [patientId, startDate, endDate, timerStatus]);

  const handleTimerUpdate = () => {
    // Fetch timers again
    fetchTimers();
  };

  if (state.isLoading) {
    return <Loader />;
  }

  if (state.isError) {
    return <h5 className="text-center pt2 pb2">{state.error}</h5>;
  }

  if (state.data.length === 0) {
    return (
      <>
        <h5>
          Time Spent:
          {' '}
          <strong>{getPrettyTime(timeSpent)}</strong>
        </h5>
        <h5 className="text-center pt2 pb2">No data found</h5>
      </>
    );
  }

  return (
    <>
      <h5>
        Time Spent:
        {' '}
        <strong>{getPrettyTime(timeSpent)}</strong>
      </h5>

      <TimeSpentGraph timersData={timers} type={type} forPrinting={forPrinting} />

      <TimersTable timersData={timers} onTimerUpdate={handleTimerUpdate} />
    </>
  );
};

SpentTimeSummary.propTypes = {
  patientId: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired,
  forPrinting: PropTypes.bool,
};

SpentTimeSummary.defaultProps = {
  forPrinting: false,
};

export default SpentTimeSummary;
