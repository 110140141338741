import React from 'react';
import PropTypes from 'prop-types';
import M from '@materializecss/materialize';

import { reducer, sendRequest } from '../../utils';

function formatDate(date) {
  if (!date) return '';

  const [year, month, day] = date.split('-');
  return `${month}/${day}/${year}`;
}

function BillingSettings({ patient }) {
  const [state, dispatch] = React.useReducer(
    reducer,
    { data: [], isLoading: true, isError: false },
  );
  const [billingDateStart, setBillingDateStart] = React.useState('');

  React.useEffect(() => {
    const elems = document.querySelectorAll('input[name="billing_date_start"]');
    M.Datepicker.init(elems, {
      autoClose: true,
      format: 'mm/dd/yyyy',
      onSelect: (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const year = date.getFullYear();
        setBillingDateStart(`${year}-${month}-${day}`);
      },
    });

    M.updateTextFields();
  }, []);

  const onSaveBillingSettings = (e) => {
    e.preventDefault();

    if (!billingDateStart) {
      dispatch({ type: 'FETCH_FAILURE', error: 'Billing Start Date is required' });
      return;
    }

    dispatch({ type: 'FETCH_INIT' });

    const opts = {
      billing_date_start: billingDateStart,
    };

    sendRequest(`connections/patients/${patient.patient.id}/config_change`, 'PUT', opts)
      .then((response) => {
        if (response.status === 'success') {
          dispatch({ type: 'FETCH_SUCCESS', message: 'Billing settings saved successfully' });
        } else {
          dispatch({ type: 'FETCH_FAILURE', error: response.message });
        }
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_FAILURE', error: typeof error === 'object' ? error.toString() : error });
      });
  };

  return (
    <>
      <div className="pt4 pb4">
        <h5 className="pb2">Billing Settings</h5>
        <form>
          <div className={`form-errors ${state.isError ? '' : 'hide'}`}>{state.error}</div>
          <div className={`form-messages ${state.message ? '' : 'hide'}`}>{state.message}</div>

          <div className="row">
            <div className="input-field col s12 m6">
              <input
                id="billing_date_start"
                name="billing_date_start"
                type="text"
                className="datepicker"
                defaultValue={formatDate(patient.billing_date_start)}
              />
              <label htmlFor="from_date">Billing Start Date</label>
            </div>

          </div>
          <button type="submit" className="btn black mr2" onClick={onSaveBillingSettings}>Save</button>
        </form>
      </div>
    </>
  );
}

BillingSettings.propTypes = {
  patient: PropTypes.shape({
    billing_date_start: PropTypes.string,
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default BillingSettings;
