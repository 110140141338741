import React from 'react';
import PropTypes from 'prop-types';

import AlertZones from './AlertZones/List';
import BillingSettings from './BillingSettings';

const PatientSettings = ({ patient }) => (
  <>
    <BillingSettings patient={patient} />
    <AlertZones patientId={patient.patient.id} />
  </>
);

PatientSettings.propTypes = {
  patient: PropTypes.shape({
    patient: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
    billing_date_start: PropTypes.string,
  }).isRequired,
};

export default PatientSettings;
