import React from 'react';
import M from '@materializecss/materialize';
import PropTypes from 'prop-types';
import moment from 'moment';

import { sendRequest } from '../../../utils';

function EditTimerModal({ patientId, timerId, currentStartDate, currentEndDate, onUpdateTimer }) {
  const [startDate, setStartDate] = React.useState(currentStartDate);
  const [endDate, setEndDate] = React.useState(currentEndDate);

  const [error, setError] = React.useState('');
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    M.updateTextFields();
  }, []);

  const validateDate = (date) => {
    const dateObj = moment(date, 'MM/DD/YYYY h:mm A', true);
    return dateObj.isValid();
  };

  const onInputChange = (e) => {
    // Validate if the input is a a valid date in format 'MM/DD/YYYY h:mm A'
    const { value } = e.target;
    const fieldName = e.target.id;

    if (fieldName === 'started_at') {
      setStartDate(value);
    } else if (fieldName === 'ended_at') {
      setEndDate(value);
    }

    if (validateDate(value)) {
      setError('');
    } else {
      setError('Invalid date format. Please use MM/DD/YYYY h:mm A');
    }
  };

  const onSaveTimerButtonClick = (e) => {
    e.preventDefault();

    // Validate dates first
    if (!validateDate(startDate) || !validateDate(endDate)) {
      setError('Invalid date format. Please use MM/DD/YYYY h:mm A');
      return;
    }

    setIsLoading(true);

    const opts = {
      // Convert the date to YYYY-MM-DDThh:mm:ssZ format
      started_at: moment(startDate, 'MM/DD/YYYY h:mm A', true).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      ended_at: moment(endDate, 'MM/DD/YYYY h:mm A', true).utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
    };

    sendRequest(`connections/patients/${patientId}/time_trackers/${timerId}/`, 'PUT', opts)
      .then((response) => {
        if (response.status === 'success') {
          M.Modal.getInstance(document.querySelector(`#edit-timer-modal-${timerId}`)).close();
          onUpdateTimer(timerId, response.data);
        } else {
          setIsLoading(false);
          setError(response.message);
        }
      })
      .catch((error) => {
        setError(typeof error === 'object' ? error.toString() : error);
      });
  };

  return (
    <>
      <div className="modal-content">
        <div className="container-fluid">
          <h5 className="pb2 text-center">Edit Timer</h5>
          <form>
            {/* Notice that history of all changes is stored to review */}
            <div className="form-warnings text-left">
              Note: All changes of timers will be logged and may be reviewed by our support team
              for quality assurance.
            </div>

            <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>

            <div className="row">
              <div className="input-field col s6">
                <input
                  id="started_at"
                  name="started_at"
                  type="text"
                  className={`validate ${validateDate(startDate) ? '' : 'invalid'}`}
                  onChange={onInputChange}
                  value={startDate}
                />
                <label htmlFor="started_at">Date Started</label>
              </div>

              <div className="input-field col s6">
                <input
                  id="ended_at"
                  name="ended_at"
                  type="text"
                  className={`validate ${validateDate(endDate) ? '' : 'invalid'}`}
                  onChange={onInputChange}
                  value={endDate}
                />
                <label htmlFor="ended_at">Date Ended</label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="modal-footer" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: '1rem' }}>
        {isLoading && (
          <div style={{ display: 'inline-block' }}>
            <div className="preloader-wrapper small active">
              <div className="spinner-layer spinner-green-only">
                <div className="circle-clipper left">
                  <div className="circle" />
                </div>
                <div className="gap-patch">
                  <div className="circle" />
                </div>
                <div className="circle-clipper right">
                  <div className="circle" />
                </div>
              </div>
            </div>
          </div>
        )}
        <button type="button" className="btn black" onClick={onSaveTimerButtonClick}>Update Timer</button>
        <button type="button" className="modal-close btn white black-text">Close</button>
      </div>
    </>
  );
}

EditTimerModal.propTypes = {
  patientId: PropTypes.string.isRequired,
  timerId: PropTypes.number.isRequired,
  currentStartDate: PropTypes.string.isRequired,
  currentEndDate: PropTypes.string.isRequired,
  onUpdateTimer: PropTypes.func.isRequired,
};

export default EditTimerModal;
