import M from '@materializecss/materialize';
import React from 'react';

import { sendRequest } from '../utils';

function ChangePasswordForm() {
  const [message, setMessage] = React.useState('');
  const [error, setError] = React.useState('');

  const [password, setPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = React.useState('');

  React.useEffect(() => {
    M.AutoInit();
  }, []);

  React.useEffect(() => {
    if (newPassword !== newPasswordConfirmation) {
      setError('Passwords do not match');
    } else {
      setError('');
    }
  }, [newPassword, newPasswordConfirmation]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const opts = {
      current_password: password,
      new_password: newPassword,
    };

    sendRequest('users/me/password_change', 'PUT', opts)
      .then((response) => {
        if (response.status === 'success') {
          setMessage('Password changed successfully');

          // Clean up form
          setPassword('');
          setNewPassword('');
          setNewPasswordConfirmation('');
          setMessage('Password changed successfully');
        } else {
          setError(response.message);
        }
      })
      .catch(() => setError('Something went wrong'));
  };

  return (
    <div className="pt2 pb2">
      <div className="row">
        <form className="col s12" onSubmit={handleSubmit}>
          <h5 className="mb2">Change Password</h5>
          <div className={`form-messages ${message ? '' : 'hide'}`}>{message}</div>
          <div className={`form-errors ${error ? '' : 'hide'}`}>{error}</div>

          <div className="row">
            <div className="input-field col s12 m4">
              <input
                id="current_password"
                name="current_password"
                type="password"
                className="validate"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <label htmlFor="current_password">Current Password</label>
            </div>
            <div className="input-field col s12 m4">
              <input
                id="new_password"
                name="new_password"
                type="password"
                className="validate"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <label htmlFor="new_password">New Password</label>
            </div>

            <div className="input-field col s12 m4">
              <input
                id="new_password_confirmation"
                name="new_password_confirmation"
                type="password"
                className="validate"
                value={newPasswordConfirmation}
                onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                required
              />
              <label htmlFor="new_password_confirmation">New Password Confirmation</label>
              <span className="helper-text" data-error={error} />
            </div>
          </div>

          <button type="submit" className="btn black">Save</button>
        </form>
      </div>
    </div>
  );
}

export default ChangePasswordForm;
